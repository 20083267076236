import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"

const Tile = css`
  position: relative;
  overflow: hidden;
  flex: 0 0 168px;
  max-width: calc(50% - 6px);
  margin: 0;
  border-radius: 4px;
  text-decoration: none;

  ${props =>
    ["text-overlay", "text-overlay-small"].includes(props.$variant)
      ? css`
          color: ${props.theme.white};
        `
      : css`
          color: ${props.theme.black};
          box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.08);
          margin-bottom: 10px;
        `}

  @media only screen and (${props => props.theme.screen.small.min}) {
    ${props =>
      props.$variant === "text-overlay-small"
        ? css`
            flex: 0 0 248px;
          `
        : css`
            flex: 0 0 312px;
          `}
    border-radius: 8px;
  }
`

const FeaturedTileLink = styled(Link)`
  ${Tile}
`
const FeaturedTile = styled.div`
  ${Tile}
`

const ImageWrapperSizeTextRibbon = styled(ImageWrapper)`
  height: 80px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 150px;
  }
`
const ImageWrapperSizeTextOverlay = styled(ImageWrapper)`
  height: 94px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 176px;
  }
`
const ImageWrapperSizeButtonOverlay = styled(ImageWrapper)`
  height: 100%;
`
const Text = styled.h4`
  ${props =>
    ["text-overlay", "text-overlay-small"].includes(props.$variant)
      ? css`
          position: absolute;
          bottom: 0px;
          padding: 4px;
          text-shadow: 1px 1px ${props.theme.black};
          @media only screen and (${props => props.theme.screen.small.min}) {
            padding: 11px 10px;
          }
        `
      : css`
          padding: 7px 6px 8px;
          @media only screen and (${props => props.theme.screen.small.min}) {
            padding: 13px 10px 15px;
          }
        `}
`
const StyledButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${props =>
    props.$variant === "button-overlay" &&
    css`
      max-width: 95%;
      @media only screen and (${props => props.theme.screen.small.min}) {
        max-width: 87%;
      }
    `}
`
const FeaturedContentTile = ({ className, content, variant }) => {
  const ImageWrapperSize =
    variant === "text-overlay"
      ? ImageWrapperSizeTextOverlay
      : variant === "button-overlay"
      ? ImageWrapperSizeButtonOverlay
      : ImageWrapperSizeTextRibbon
  const TileContent = []
  if (content.title) {
    if (variant === "button-overlay") {
      TileContent.push(
        <StyledButton key="button" variant="rounded">
          {content.title}
        </StyledButton>
      )
    } else {
      TileContent.push(
        <Text key="text" $variant={variant}>
          {content.title}
        </Text>
      )
    }
  }

  const populatedTile = content && (
    <>
      <Image placeholder wrapper={ImageWrapperSize} {...content.image} />
      {TileContent}
    </>
  )
  return content.link ? (
    <FeaturedTileLink
      $variant={variant}
      className={className}
      to={content.link}
    >
      {populatedTile}
    </FeaturedTileLink>
  ) : (
    <FeaturedTile $variant={variant} className={className}>
      {populatedTile}
    </FeaturedTile>
  )
}
FeaturedContentTile.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "text-overlay",
    "text-ribbon",
    "button-overlay",
    "text-overlay-small",
  ]),
  content: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.shape({ ...ImagePropTypes }),
  }),
}
export default FeaturedContentTile
