export const DIFFICULTY_RATING_SYMBOLS = [
  {
    value: 1,
    symbol: "circle",
    title: "Easy (Circle)",
    description:
      "Suitable for beginners, families, young children, and those looking for a casual adventure.",
  },
  {
    value: 2,
    symbol: "square",
    title: "Intermediate (Square)",
    description:
      "Suitable for intermediates who are ready for a little bit more adventure.",
  },
  {
    value: 3,
    symbol: "diamond",
    title: "Advanced (Diamond)",
    description:
      "Suitable for advanced experience level looking for a solid adventure.",
  },
  {
    value: 4,
    symbol: "double-diamond",
    title: "Expert (Double Diamond)",
    description:
      "Suitable only for experts looking for a challenging adventure.",
  },
]
