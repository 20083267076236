import generateAggregateRating from "./generateAggregateRating"
import generateUserReview from "./generateUserReview"
import generateGeoCoordinates from "./generateGeoCoordinates"
/*
LocalBusiness:
  - Google: https://developers.google.com/search/docs/advanced/structured-data/local-business
  - Schema.org: https://schema.org/LocalBusiness
*/
const generateLocalBusiness = data => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    address: {
      "@type": "PostalAddress",
      name: data.parentName,
    },
    name: data.name,
  }
  if (data.ratings) {
    structuredData["aggregateRating"] = generateAggregateRating(data.ratings)
    if (data.ratings.reviews && data.ratings.reviews.length) {
      structuredData["review"] = data.ratings.reviews.map(review =>
        generateUserReview(review)
      )
    }
  }
  if (data.coordinates) {
    structuredData["geo"] = generateGeoCoordinates(data.coordinates)
  }
  if (data.description) {
    structuredData["description"] = data.description
  }
  if (data.image) {
    structuredData["image"] = data.image
  }
  return structuredData
}

export default generateLocalBusiness
