import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TrailCard from '@src/components/trail-card';
import ContentCarousel from "./container-carousel"

const TrailCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ $count, $rows }) =>
    `repeat(${Math.ceil($count / $rows)}, 1fr)`};
  grid-template-rows: ${({ $rows }) => `repeat(${$rows}, 1fr)`};
  grid-gap: 16px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-gap: 50px;
  } 
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    grid-gap: 58px;
  }  
`

const Carousel = styled(ContentCarousel)`
  & > .carousel-content {
    height: 458px;
    width: 350px;
    gap: 16px;
    & > * {
      margin: 0 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  & > button {
    display: none;
    top: -30px;
    &.prev {
      left: 0px;
    }
    &.next {
      left: 42px;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    max-width: 768px;
    & > .carousel-content {
      height: 458px;
      width: 768px;
      > *:not(:last-child) {
        margin: 0 30px 0 0;
      }
    }
    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0px 4px 4px 0px #a0a0a040;
      background: ${props => props.theme.white};
      color: ${props => props.theme.white};
      background: #1d1d1d;
      &:hover {
        color: ${props => props.theme.primary};
      }
      &:not(.show) {
        background: ${props => props.theme.white};
        color: #667085;
      }
      top: -30px;
      &.prev {
        left: auto;
        right: 42px;
      }
      &.next {
        left: auto;
        right: 0px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    max-width: 958px;
    & > .carousel-content {
      height: 458px;
      width: 958px;
    }
    & > button {
      &.prev {
        left: auto;
        right: 42px;
      }
      &.next {
        left: auto;
        right: 0px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    max-width: 1135px;
    & > .carousel-content {
      gap: 0px;
      height: 458px;
      width: 1140px;
      > *:not(:last-child) {
        margin: 0 58px 0 0;
      }
    }
    & > button {
      &.prev {
        left: auto;
        right: 42px;
      }
      &.next {
        left: auto;
        right: 0px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    max-width: 1441px;
    & > .carousel-content {
      gap: 0px;
      height: 458px;
      width: 1276px;
    }  
`

const ContentHeader = styled.h2`
  color: #101828;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: system-ui;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.5px;
  width: 345px;
  margin-top: 56px;
  
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 768px;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 958px;
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    width: 1135px;
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    width: 1276px;
  }
`

const renderedItem = trail => {
    return (
        <TrailCard
          key={trail.title}
          title={trail.title}
          image={trail.images?.header?.src}
          location={trail.region.name}
          difficulty={trail.difficulty}
          distance={trail.distance}
          elevation={trail.elevation}
          duration={trail.duration}
          link={trail.link}
        />
    )
}

const renderGrids = content => {
  const grids = [];
  for (let i = 0; i < content.length; i += 3) {
    const items = [];
    for (let j = 0; j < 3; j++) {
      if (i + j < content.length) {
        items.push(renderedItem(content[i + j]));
      }
    }

    grids.push(
      <Grid $count={items.length} $rows={1} key={Math.random()}>
        {items}
      </Grid>
    );
  }
  return grids;
};

const TrailCardList = ({ trails }) => {
  return (
    <TrailCardListContainer>
      <ContentHeader>Nearby Trails</ContentHeader>
      {trails && trails.length && (
        <Carousel
          icons={{
            left: "v2-arrow-small-left",
            right: "v2-arrow-small-right",
          }}
          mobileSizeOnly
          alwaysShowButtons
        >
          {trails.length > 1 ? renderGrids(trails) : { trails }}
        </Carousel>
      )}
    </TrailCardListContainer>
  );
};

TrailCardList.propTypes = {
  trails: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      images: PropTypes.shape({
        header: PropTypes.shape({
          src: PropTypes.string,
        }),
      }),
      region: PropTypes.shape({
        name: PropTypes.string,
      }),
      difficulty: PropTypes.string,
      distance: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
      }),
      elevation: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
      }),
      duration: PropTypes.string,
    })
  )
};

export default TrailCardList;