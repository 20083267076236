import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from "@src/components/core-icon"
import Link from "@src/components/core-link"
import { DIFFICULTY_RATINGS } from "@src/utils/constants/difficulty-ratings"

const Card = styled.div`
  overflow: hidden;
  width: 283px;
  height: 458px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #757575;
  display: flex;
  flex-direction: column;
   
  @media only screen and (${props => props.theme.screen.large.min}) {
    width: 386px;
    height: 435px;
  }    
`;

const Image = styled.img`
  box-sizing: content-box;
  width: 259px;
  height: 252px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 16px;
  margin: 12px;
   
  @media only screen and (${props => props.theme.screen.large.min}) {
    width: 362px;
    height: 253.4px;
  }   
`;

const Content = styled.div`
  padding: 0 16px 16px;
  display: grid;
  grid-template-rows: 85px 45px;
`;

const Title = styled.h3`
  font-size: 18px;
  margin: auto;
  color: #1F1F1F;
  font-family: system-ui;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

const Location = styled.p`
  color: #5B5F62;
  font-family: system-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.14px;
  margin-top: 6px;
`;

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;
  padding-top: 13px;
  justify-content: space-between;

  @media only screen and (${props => props.theme.screen.large.min}) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex: 1;
    padding-top: 0px;
  }
`;

const Detail = styled.div`
  display: flex;
  align-items: center;
  color: #26383B;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Subheadings/Subhead 2 */
  font-family: system-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
  letter-spacing: 0.25px;

  @media only screen and (${props => props.theme.screen.large.min}) {
    justify-content: space-between;
    padding-left: 5px;

    &.first {
      padding-left: 0px;
    }
  }
`;

const StyledIcon = styled(Icon) `
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 7px;
  color: #26383B;
`

const getRating = (rating) => {
  return DIFFICULTY_RATINGS.find(item => item.value === Number(rating)).title;
}

const TrailCard = ({ title, image, location, difficulty, distance, elevation, duration, link }) => (
  <Card>
    {image && <Image src={image} alt={title} />}
      <Content>
        <div>
          <Link to={link} variant="compact">
            <Title>{title}</Title>
          </Link>
          <Location>{location}</Location>
        </div>
        <Details>
          {difficulty && <Detail className="first"><StyledIcon glyph="v2-thick-Fire" iconFontSize="14px"/>{getRating(difficulty)}</Detail>}
          {distance && <Detail><StyledIcon glyph="v2-backpack" iconFontSize="14px"/>{distance.value} {distance.unit}</Detail>}
          {elevation && <Detail><StyledIcon glyph="v2-mountains" iconFontSize="14px"/>{elevation.value} {elevation.unit}</Detail>}
          {duration && <Detail><StyledIcon glyph="v2-stopwatch" iconFontSize="14px"/>{duration}</Detail>}
        </Details>
      </Content>
  </Card>
);

TrailCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  location: PropTypes.string,
  difficulty: PropTypes.string,
  distance: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
  }),
  elevation: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
  }),
  duration: PropTypes.string,
  link: PropTypes.string
};

export default TrailCard;