import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import ImageGallery from "@src/components/core-image-gallery"
import Button from "@src/components/core-button"

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 5px;

  & > .gallery-button {
    z-index: 3;
    padding: 0;
  }
`

const ImageGalleryPopout = ({ images, className, open, onClose }) => {
  const [galleryIsOpen, setGalleryIsOpen] = useState(open)
  const handleClick = () => {
    setGalleryIsOpen(true)
  }
  useEffect(() => {
    setGalleryIsOpen(open)
  }, [open])
  const handleClose = () => {
    setGalleryIsOpen(false)
    onClose && onClose()
  }
  const arrowIconNames = ["chevron-left", "chevron-right"]
  return (
    <ButtonContainer className={className}>
      {arrowIconNames.map(iconName => (
        <Button
          key={iconName}
          variant="circle"
          icon={iconName}
          onClick={handleClick}
          className="gallery-button"
          color="white-gray"
        />
      ))}
      <ImageGallery
        variant="popup"
        images={images}
        open={galleryIsOpen}
        onClose={handleClose}
      />
    </ButtonContainer>
  )
}

ImageGalleryPopout.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ImageGalleryPopout
