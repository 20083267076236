import React from "react"
import styled from "styled-components"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import Link from "@src/components/core-link"

const StoreBadgeWrappers = styled(ImageWrapper)`
  width: 160px;
  // Specifically needed to align and maintain same sizes for app store and google play store download badges
  // https://stackoverflow.com/questions/47590571/increase-margin-on-app-store-badge-to-match-size-of-google-play-store-badge
  & > .lazyload-wrapper > img.app-store-download {
    width: 82%;
    margin: 6%;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 150px;
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    width: 200px;
    margin-top: 8px;
  }
`

const AppDownloadLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  left: 10px;
  bottom: 0;
  z-index: 3;
`

const MapCTA = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 34px;

  h3 {
    color: #26383B;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Buttons/Button M */
    font-family: system-ui;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  
  @media only screen and (${props => props.theme.screen.small.min}) {
    display: none;
  }
 
`

const DetailsPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: 239px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #EAECF0;
`

const ImageDiv = styled.div`
  overflow: hidden;
  position: relative;
  width: 313px;
  height: 151px;
  background: #B7D7CD;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  margin: 16px 16px 0 16px;
`

const AppInHandImage = styled(Image)`
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -62%);
  margin: 0;
`

const ScreenshotImage = styled(Image)`
  border-radius: 5.28px;
  position: absolute;
  top: 65%;
  left: 44.65%;
  transform: translate(-50%, -62%);
  margin: 0;
`

const InteractiveMapCTA = () => {
  const appStoreLink = process.env.GATSBY_10A_APP_STORE_LINK
  const playStoreLink = process.env.GATSBY_10A_PLAY_STORE_LINK
  return (
    <MapCTA>
        <h3>Download an interactive map and guide on your phone today!</h3>
        <DetailsPanel>
          <ImageDiv>
            <AppInHandImage
              placeholder
              src="/images/app-in-hand.jpg"
              alt="10adventures-mobile-screenshot"
              height={217}
              width={173}
            />
            <ScreenshotImage
              placeholder
              src="/images/app-in-hand-screenshot.jpg"
              alt="10adventures-mobile-screenshot"
              height={105}
              width={52.5}
            />
          </ImageDiv>
          <AppDownloadLinks>
            <Link to={playStoreLink}>
                <Image
                  src="/icons/google-play-badge.png"
                  alt="Play store icon"
                  wrapper={StoreBadgeWrappers}
                  width={166}
                  height={65}
                />
              </Link>
              <Link to={appStoreLink}>
                <Image
                  className="app-store-download"
                  src="/icons/app-store-badge.svg"
                  alt="App store icon"
                  wrapper={StoreBadgeWrappers}
                  width={129}
                  height={43}
                />
              </Link>
          </AppDownloadLinks>
        </DetailsPanel>
    </MapCTA>
  )
}

export default InteractiveMapCTA
