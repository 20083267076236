import React, { useContext, useState, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import RouteReviewsService from "@src/services/reviews/route-reviews"
import { UserContext } from "@src/context/user-context"
import Form, { FormGroup, FormActions } from "@src/components/form"
import FormFieldInputText from "@src/components/form-field-input-text"
import FormFieldInputTextarea from "@src/components/form-field-input-textarea"
import FormFieldInputNumber from "@src/components/form-field-input-number"
import FormFieldInputRatingStars from "@src/components/form-field-input-rating-stars"
import FormFieldInputRatingDifficultySymbols from "@src/components/form-field-input-rating-difficulty-symbols"
import Link from "@src/components/core-link"
import Button from "@src/components/core-button"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"

const ReviewDetails = styled.div`
  margin-bottom: 10px;
  font-weight: 500;
  & > label {
    display: inline-block;
    font-weight: normal;
    font-style: italic;
    font-size: 0.8em;
  }

  .field-header {
    color: #1f1f23;
    font-feature-settings: "clig" off, "liga" off;

    /* Inputs/Input Title */
    font-family: system-ui;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`
const StyledRatingStars = styled(FormFieldInputRatingStars)`
  @media only screen and (${props => props.theme.screen.tablet.max}) {
    width: 100%;
  }
`
const StyledRatingsFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  & > div {
    margin: 0 10px 15px;
  }
  & > label {
    color: #1f1f23;
    font-feature-settings: "clig" off, "liga" off;

    /* Inputs/Input Title */
    font-family: system-ui;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`
// for some reason the tooltip on this one are off-center unless width is >= 150px
const StyledPhysicalDifficulty = styled(FormFieldInputRatingDifficultySymbols)`
  width: 250px;
`
const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 100%;
  }
`

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 56px 56px 32px;
  border: 1px solid #888;
  border-radius: 16px;
  background: #fff;
  width: 100vw;
  height: 100vh;
  flex-shrink: 0;

  .header {
    color: #315254;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Headings/Heading3 */
    font-family: system-ui;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 137.5% */
    letter-spacing: 0.5px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 528px;
    height: 776px;
  }
`
const CloseButton = styled.span`
  float: right;
  color: #1f1f23;
  font-size: 40px;
  cursor: pointer;
  display: flex;
  width: 13.414px;
  height: 13.414px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`
const StyledFormActions = styled(FormActions)`
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    width: 100vw;
    padding: 16px 28px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #2b4346;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
`
const WriteReviewButton = styled.div`
  display: inline-flex;
  width: -webkit-fill-available;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #1f1f23;
  margin-top: 24px;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: system-ui;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.09px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 184px;
    height: 50px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #1f1f23;
    cursor: pointer;
    margin-bottom: 50px;
  }
`

const ReviewHeaderDiv = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 24px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 100px;
    margin-bottom: 10px;
  }
`

const RatingDiv = styled.div`
  margin-bottom: 24px;
  width: 50%;
  height: 86px;
  float: left;
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 50%;
    height: 86px;
    float: left;
  }
`

const StyledStar = styled(IconDetailContentTile)`
  display: inline-flex;
  margin-top: 16px;
  width: 34px;
  height: 32px;
  float: left;
  & .icon {
    font-size: 34px;
  }
`

const AverageRating = styled.div`
  display: inline-flex;
  margin-left: 10px;
  width: 77px;
  height: 64px;
  color: #272d37;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui;
  font-size: 46px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px; /* 139.13% */
  letter-spacing: 0.5px;
`
const TotalReviews = styled.div`
  margin-left: 44px;
  color: #4f4f4f;
  font-family: system-ui;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
`

const AddRouteGuideReviewModal = ({
  routeId,
  onReviewAdded,
  overallRating,
  totalReviews,
}) => {
  const { user } = useContext(UserContext)
  const [formStatus, setFormStatus] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleFormSubmit = useCallback(
    async (values, resetForm) => {
      setFormStatus({
        processing: "Thanks! Just one moment while we post your review...",
      })

      try {
        await RouteReviewsService.createRouteReview(routeId, values)

        // API call didn't throw an error, assume a success
        setFormStatus({
          success: "Thanks for the review!",
        })
        if (onReviewAdded) {
          onReviewAdded()
        }
        resetForm()
      } catch (error) {
        setFormStatus({
          error: "There was an error posting your review.",
        })
      }
    },
    [routeId, onReviewAdded, setFormStatus]
  )
  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    console.log("Close button clicked")
    setIsModalOpen(false)
  }

  return (
    <>
      <ReviewHeaderDiv>
        <RatingDiv>
          <StyledStar glyph="star" color="primary" size="medium" />
          <AverageRating>{overallRating}</AverageRating>
          {totalReviews !== 0 ? (
            <TotalReviews>{totalReviews} reviews</TotalReviews>
          ) : (
            <TotalReviews>No reviews</TotalReviews>
          )}
        </RatingDiv>
        <WriteReviewButton onClick={openModal}>
          Write a review
        </WriteReviewButton>
      </ReviewHeaderDiv>
      <Modal isOpen={isModalOpen}>
        <ModalContent>
          <CloseButton onClick={closeModal}>&times;</CloseButton>
          <h3 className="header">Add a review</h3>
          {user ? (
            <Form name="route-review" onSubmit={handleFormSubmit}>
              <StyledRatingsFormGroup>
                <StyledRatingStars
                  label="Overall Rating"
                  name="ratings[overall]"
                  max={10}
                />
                <FormFieldInputRatingDifficultySymbols
                  label="Technical Difficulty"
                  name="ratings[technical]"
                />
                <StyledPhysicalDifficulty
                  label="Physical Difficulty"
                  name="ratings[physical]"
                />
              </StyledRatingsFormGroup>
              <ReviewDetails>
                <label className="field-header">Title</label>
                <FormFieldInputText
                  name="review-title"
                  placeholder="Enter a tagline for your adventure!"
                  validation={{
                    required: "The tagline field is required.",
                  }}
                />
              </ReviewDetails>
              <ReviewDetails>
                <label className="field-header">Trip Length</label>
              </ReviewDetails>
              <FormGroup>
                <FormFieldInputNumber
                  name="time-taken[hours]"
                  placeholder="hours"
                  separator=""
                  padding={2}
                  min={0}
                  size="4"
                />
                <FormFieldInputNumber
                  name="time-taken[minutes]"
                  placeholder="minutes"
                  separator=""
                  padding={2}
                  min={0}
                  max={59}
                  size="6"
                />
              </FormGroup>
              <ReviewDetails>
                <label className="field-header">Description</label>
                <FormFieldInputTextarea
                  name="review-content"
                  placeholder="Share your experience with fellow adventurers...
How were the trail conditions?
What were some highlights?
What should people know about this trip?"
                  rows="6"
                />
              </ReviewDetails>
              <StyledFormActions
                className="horizontal"
                submitting={
                  formStatus && formStatus.processing ? true : undefined
                }
              >
                {(formStatus?.success && (
                  <p className="form-success">{formStatus.success}</p>
                )) ||
                  (formStatus?.error && (
                    <p className="form-error">{formStatus.error}</p>
                  )) ||
                  (formStatus?.processing && (
                    <p className="form-info">{formStatus.processing}</p>
                  ))}
                <Button
                  type="submit"
                  disabled={
                    formStatus && formStatus.processing ? true : undefined
                  }
                >
                  Submit Review
                </Button>
              </StyledFormActions>
            </Form>
          ) : (
            <p>
              You must be <Link to="/account/login/">logged in</Link> to post a
              comment.
            </p>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
AddRouteGuideReviewModal.propTypes = {
  routeId: PropTypes.number,
  onReviewAdded: PropTypes.func,
  overallRating: PropTypes.string,
  totalReviews: PropTypes.number,
}
export default AddRouteGuideReviewModal
