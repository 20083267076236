import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { window, exists } from "browser-monads"
import theme from "@src/styles/theme"
import TableOfContents from "@src/components/core-table-of-contents-v2"
import Image, { ImagePropTypes } from "@src/components/core-image"
import Link from "@src/components/core-link"
import ImageWrapper from "@src/components/core-image-wrapper"
import Button from "@src/components/core-button"
import { BASE_PATH_MAP } from "@src/utils/constants"
import { LocaleContext } from "@src/context/locale-context"
import InputUnitsSwitch from "@src/components/input-units-switch"
import InteractiveMapCTA from "@src/components/interactive-map-cta"

const StyledTableOfContentsTitle = styled.h3`
  color: #26383b;
  font-feature-settings: "clig" off, "liga" off;

  /* Subheadings/Subhead 4 */
  font-family: system-ui;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 150% */
  letter-spacing: 0.25px;
  margin-bottom: 16px;
`
const StyledTableOfContentsImageWrapper = styled(ImageWrapper)`
  height: 130px;
  border-radius: 8px;
`

const ExtendedStyledTableOfContents = styled(TableOfContents)`
  margin-top: 335px;
  position: static;

  ${StyledTableOfContentsTitle} {
    display: none;
  }

  li {
    color: #667085;
    font-feature-settings: "clig" off, "liga" off;

    /* Links/Link S */
    font-family: system-ui;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    letter-spacing: 0.07px;
    text-decoration-line: underline;
    text-underline-offset: 3px;
  }

  @media only screen and (${props => props.theme.header.medium.min}) {
    position: sticky;
    margin-top: 50px;
    top: ${props => props.theme.header.medium.height + 50}px;
    border-radius: 8px;

    & > .table-of-contents {
      max-height: calc(
        100vh - ${props => props.theme.header.medium.height + 50}px
      );
    }

    ${StyledTableOfContentsTitle},
    ${StyledTableOfContentsImageWrapper} {
      display: block !important;
    }
  }

  @media only screen and (${props => props.theme.header.large.min}) {
    position: sticky;
    margin-top: 50px;
    top: ${props => props.theme.header.large.height + 50}px;

    & > .table-of-contents {
      max-height: calc(
        100vh - ${props => props.theme.header.large.height + 50}px
      );
    }
  }
`

const MapWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  background: lightgray 50% / cover no-repeat;
`
const MapButton = styled(Button)`
  display: inline-block;
  position: absolute;
  right: -10px;
  bottom: 5px;
  width: 85px;
  height: 27px;
  margin-right: 18px;
  padding: 5px 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6.019px;
  border: 1px solid #26383b;
  background: #fff;
  color: #26383b;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Buttons/Button S */
  font-family: system-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */

  :hover {
    color: #3a6968;
  }
`
const MeasurementSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 19px;
`
const MeasurementSwitch = styled.div`
  border-radius: 8px;
  border: 1px solid #757575;
  box-shadow: 0px 24px 90px 0px rgba(192, 188, 161, 0.22);
  width: 184px;
  height: 41px;
  flex-shrink: 0;
  display: flex;
  padding: 10px;
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: space-evenly;
  align-items: center;

  .measurement-label {
    color: #272d37;
    font-feature-settings: "clig" off, "liga" off;
    font-family: system-ui;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
    letter-spacing: 0.25px;
    width: 95px;
    height: 21px;
  }
`

const TableOfContentsActivity = ({
  title,
  image,
  entries,
  className,
  gps,
  mapPath,
  ...props
}) => {
  const scrollOffset =
    exists(window) && window.innerWidth < theme.screen.small.minWidth
      ? -180
      : -150
  return (
    <ExtendedStyledTableOfContents
      header={
        <>
          {image && (
            <MapWrapper>
              <Link
                to={`${BASE_PATH_MAP}${mapPath ? `${mapPath}/` : ""}`}
                gps={gps}
                rel="nofollow"
              >
                <Image wrapper={StyledTableOfContentsImageWrapper} {...image} />
              </Link>
              <MapButton
                to={`${BASE_PATH_MAP}${mapPath ? `${mapPath}/` : ""}`}
                gps={gps}
                rel="nofollow"
                size="small"
                variant="rounded"
              >
                See Map
              </MapButton>
            </MapWrapper>
          )}
          <MeasurementSwitchContainer>
            <MeasurementSwitch>
              <span className="measurement-label">Measurement: </span>
              <LocaleContext.Consumer>
                {({ units, setPreferredUnits }) => (
                  <InputUnitsSwitch
                    id="header-user-units"
                    name="header-user-units"
                    value={units}
                    onChange={setPreferredUnits}
                    variant="green2"
                  />
                )}
              </LocaleContext.Consumer>
            </MeasurementSwitch>
            <InteractiveMapCTA />
          </MeasurementSwitchContainer>
          {title && (
            <StyledTableOfContentsTitle>{title}</StyledTableOfContentsTitle>
          )}
        </>
      }
      entries={entries}
      scrollOffset={scrollOffset}
      className={className}
      {...props}
    />
  )
}
TableOfContentsActivity.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({ ...ImagePropTypes }),
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  gps: PropTypes.oneOfType([
    PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number })
    ),
  ]),
  mapPath: PropTypes.string,
}
export default TableOfContentsActivity
