import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { LocaleContext } from "@src/context/locale-context"
import StickyCollapsePanel from "@src/components/container-panel-sticky-collapse"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import DummyImageWrapper from "@src/components/core-image-dummy-wrapper"
import TripPlanners from "@src/components/content-trip-planners"
import Button from "@src/components/core-button"
import InputUnitsSwitch from "@src/components/input-units-switch"
import ImageGalleryPopout from "@src/components/core-image-gallery-popout"
import { whiteTextBlackOutline } from "@src/styles/elements"
import {
  ImagePropTypes,
  ImageCreditsPropTypes,
} from "@src/components/core-image"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"
import BreadcrumbsV2 from "@src/components/nav-content-breadcrumbs-v2"
import { ROUTE_TO_TOUR_BACKLINKS } from "@src/utils/constants/route-tour-backlinks"

const StyledInputUnitsSwitch = styled(InputUnitsSwitch)`
  position: absolute;
  z-index: ${props => props.theme.layers.floatingUI + 80};
  right: 15px;
  top: 10px;
  @media only screen and (${props => props.theme.screen.tablet.min}) {
    top: 16px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    top: 14px;
  }
  @media only screen and (${props => props.theme.header.small.min}) {
    top: 16px;
    right: 45px;
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    right: 55px;
  }
`
const StyledBreadcrumbNavV2 = styled(BreadcrumbsV2)`
  padding: 10px 75px 10px 16px;
  @media only screen and (${props => props.theme.screen.tablet.min}) {
    padding: 16px 75px 16px 16px;
  }
  @media only screen and (${props => props.theme.header.small.min}) {
    padding: 16px 105px 16px 28px;
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    padding: 16px 115px 16px 28px;
    margin-left: 112px;
  }
`
const StyledStickyCollapsePanel = styled(StickyCollapsePanel)`
  position: static;
  z-index: ${props => props.theme.layers.floatingUI + 75};
  overflow: visible;
  @media only screen and (${props => props.theme.screen.small.min}) {
    position: sticky;
    overflow: hidden;
  }
`
const HeaderContentStyles = css`
  width: 100%;
  padding: 0 16px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: auto;
    grid-row: 5 / 6;
    grid-column: 1 / 3;
    padding: 0 30px;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    ${props => (props.variant === "overlay" ? `margin-top: 20px;` : null)}
    padding: 20px 80px 10px 236px;
  }
`
const HeaderContent = styled.div`
  ${HeaderContentStyles}

  width: 796px;
  display: flex;
  justify-content: center;
  & button {
    margin: 10px auto;
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    grid-template-columns: auto minmax(800px, 1000px);
  }
`
const HeaderTripPlanners = styled.div`
  ${HeaderContentStyles}
`
const HeaderTripPlannersTitle = styled.h2`
  margin: 0 0 10px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin: 0 0 20px;
  }
`
const HeaderTitle = styled.h1`
  width: 50%;
  text-align: center;
  color: #FFF; 
  font-feature-settings: 'clig' off, 'liga' off;
  z-index: 1;
  font-family: system-ui;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;  
  letter-spacing: 0.5px;
  position: absolute;

  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    font-size: 46px;
    font-weight: 900;
    line-height: 64px;
  }

`
const StyledImage = styled(Image)`
  filter: brightness(75%);
`
const HeaderImage = styled.div`
  height: 416px;
  width: 99vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 99vw;
    grid-row: 2 / 4;
    grid-column: 1 / 2;
  }
`

const StyledImageGalleryPopout = styled(ImageGalleryPopout)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  
  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 20px;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    padding: 157px;
  }

`
const HeaderImageWrapper = styled(ImageWrapper)`
  width: 100%;
  height: 100%;
`
const HeaderDummyImageWrapper = styled(DummyImageWrapper)`
  width: 100%;
  height: 100%;
  &::before {
    top: 20px;
    left: 20px;
    right: 20px;
  }
  &::after {
    bottom: 10px;
    left: 10px;
    right: 10px;
    text-align: right;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    &::after {
      bottom: 165px;
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    &::after {
      bottom: 130px;
    }
  }
`

const UltimateGuideButton = styled(Button)``
const HeaderUltimateGuide = styled.div`
  position: relative;
  height: 74px;
  margin-top: 10px;
  & > .lazyload-wrapper {
    height: 100%;
    width: 100%;
    & > picture > img {
      filter: brightness(75%);
    }
  }
  & > ${UltimateGuideButton} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 125px;
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    margin-top: 0px;
    margin-left: 10px;
  }
`
const HeaderStyled = styled.section`
  display: grid;
  & ${HeaderTripPlanners}, & ${HeaderContent} {
  }
`

const TitleOverlay = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #EAECF0;
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
  justify-content: center;
  width: 88%;
  position: absolute;
  margin-top: 263px;
  z-index: 2;
  padding: 0 16px;
  grid-row: 3 / 4;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (${props => props.theme.screen.small.min}) {
    grid-column: 1 / 3;
    padding: 0 30px;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.08);
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: calc(100vw - 300px);
    position: relative;
    padding: 0 20px;
    z-index: 2;
    left: 0;
    right: 0;
    margin-top: 342px;
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    margin-top: 331px;
    width: 843px;
  }
`
const HeaderTitleContents = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  left: 16px;
  & > ${HeaderTitle}, & > .core-icon-content {
    ${whiteTextBlackOutline}
  }
  & > .core-icon-content {
    align-items: center;
    font-weight: 500;
  }
  @media only screen and (${props => props.theme.screen.tablet.max}) {
    & > .core-icon-content {
      font-size: 0.75rem;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    bottom: 60px;
    left: 30px;
    & > .core-icon-content {
      font-weight: 600;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    left: 80px;
  }
`

const RegionHeader = ({
  id,
  title,
  image,
  imageOverlayText,
  imageDummy,
  gallery,
  breadcrumbs,
  tripPlanners,
  ultimateGuide,
  variant,
  withUnitsSwitch,
  overlay,
  galleryOpen,
  onGalleryClose,
  regionDetails,
  pagePath,
}) => {
  const routeTourBacklink = ROUTE_TO_TOUR_BACKLINKS[pagePath]
  const ultimateGuideImage = {
    src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile.jpg",
    width: 1900,
    height: 373,
    alt: "A landscape of a blue lake it's rocky shoreline, lined with autumn-coloured trees.",
    responsive: [
      {
        target: "mobile",
        sources: [
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-375x74.jpg",
            width: 375,
            height: 74,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-750x148.jpg",
            width: 750,
            height: 148,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-768x74.jpg",
            width: 768,
            height: 74,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-1125x222.jpg",
            width: 1125,
            height: 222,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-1536x148.jpg",
            width: 1536,
            height: 148,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-1900x222.jpg",
            width: 1900,
            height: 222,
          },
        ],
      },
      {
        target: "small",
        sources: [
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-410x125.jpg",
            width: 410,
            height: 125,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-776x125.jpg",
            width: 776,
            height: 125,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-820x250.jpg",
            width: 820,
            height: 250,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-1230x375.jpg",
            width: 1230,
            height: 375,
          },
          {
            src: "https://d3owbckoeihl9o.cloudfront.net/images/10adv/wp-content/uploads/2022/08/Option-1-1900x373-Ultimate-Guide-long-tile-1552x250.jpg",
            width: 1552,
            height: 250,
          },
        ],
      },
    ],
  }

  if (tripPlanners && tripPlanners.length) {
    tripPlanners.sort((a, b) => {
      if (a.primary && !b.primary) {
        return -1
      } else if (!a.primary && b.primary) {
        return 1
      }
      if (a.title?.includes("Ultimate") && !b.title?.includes("Ultimate")) {
        return -1
      } else if (
        !a.title?.includes("Ultimate") &&
        b.title?.includes("Ultimate")
      ) {
        return 1
      }
      if (a.date && b.date) {
        if (a.date < b.date) {
          return -1
        } else if (a.date > b.date) {
          return 1
        }
      }
      if (a.title < b.title) {
        return -1
      } else if (a.title > b.title) {
        return 1
      }
      return 0
    })
  }
  const UltimateGuide =
    ["general", "general2"].includes(variant) &&
    (ultimateGuide || routeTourBacklink) ? (
      <HeaderUltimateGuide>
        <Image
          artDirection
          {...ultimateGuideImage} /*{...ultimateGuide.images.headerTile} */
        />
        <UltimateGuideButton
          to={routeTourBacklink?.link || ultimateGuide.uri}
          variant="rounded"
          size="small"
        >
          {routeTourBacklink?.buttonText || ultimateGuide.title}
        </UltimateGuideButton>
      </HeaderUltimateGuide>
    ) : undefined

  let imageWrapperType = HeaderImageWrapper
  const imageWrapperProps = imageOverlayText
    ? {
        overlayText: imageOverlayText.reduce(
          (result, { key, value }) => ({ ...result, [key]: value }),
          {}
        ),
      }
    : undefined
  if (imageDummy && imageWrapperProps) {
    imageWrapperType = HeaderDummyImageWrapper
    imageWrapperProps.dummyName = imageDummy
  }

  // Location Message
  const grandparentName = regionDetails && regionDetails.grandparent
  const locationMessage =
    regionDetails &&
    regionDetails.regionTypeName &&
    regionDetails.parent &&
    `${regionDetails.regionTypeName} in ${regionDetails.parent}${
      grandparentName ? `, ${grandparentName}` : ""
    }`
  return (
    <>
      <StyledStickyCollapsePanel>
        <StyledBreadcrumbNavV2
          breadcrumbItems={breadcrumbs}
          withUnitsSwitch={withUnitsSwitch}
        />
        {withUnitsSwitch ? (
          <LocaleContext.Consumer>
            {({ units, setPreferredUnits }) => (
              <StyledInputUnitsSwitch
                id="header-user-units"
                name="header-user-units"
                value={units}
                onChange={setPreferredUnits}
              />
            )}
          </LocaleContext.Consumer>
        ) : null}
      </StyledStickyCollapsePanel>
      <div id={id}>
        <HeaderStyled>
          {variant === "overlay" && (
            <TitleOverlay>
              {overlay}
            </TitleOverlay>
          )}
          <HeaderImage>
            <HeaderTitle>{title}</HeaderTitle>
            <StyledImage
              artDirection={{ mobile: "100vw", small: "60vw" }}
              lazyload={false}
              wrapper={imageWrapperType}
              wrapperProps={imageWrapperProps}
              {...image}
            />
            {gallery && gallery.length >= 1 && (
              <StyledImageGalleryPopout
                images={gallery}
                open={galleryOpen}
                onClose={onGalleryClose}
              />
            )}
            {variant !== "overlay" && (
              <HeaderTitleContents>
                <HeaderTitle className="region-title" $onlyTitle={!overlay}>
                  {title}
                </HeaderTitle>
                {locationMessage && (
                  <IconDetailContentTile glyph="pin-round" size="small">
                    {locationMessage}
                  </IconDetailContentTile>
                )}
              </HeaderTitleContents>
            )}
          </HeaderImage>
          {tripPlanners &&
          tripPlanners.length &&
          !["general", "general2"].includes(variant) ? (
            <HeaderTripPlanners>
              <HeaderTripPlannersTitle>Trip Planners</HeaderTripPlannersTitle>
              <TripPlanners content={tripPlanners} />
            </HeaderTripPlanners>
          ) : null}
          {variant === "general" && UltimateGuide}
          {variant === "general2" && UltimateGuide}
        </HeaderStyled>
      </div>
    </>
  )
}
RegionHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  imageOverlayText: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  imageDummy: PropTypes.string,
  map: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      credits: PropTypes.shape({ ...ImageCreditsPropTypes }),
    })
  ),
  gps: PropTypes.oneOfType([
    PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number })
    ),
  ]),
  mapPath: PropTypes.string,
  content: PropTypes.node,
  contentButton: PropTypes.bool,
  tripPlanners: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.object,
      link: PropTypes.string,
      primary: PropTypes.bool,
    })
  ),
  ultimateGuide: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
    images: PropTypes.shape({
      headerTile: PropTypes.shape({ ...ImagePropTypes }),
    }),
  }),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  breadcrumbAddLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  variant: PropTypes.oneOf(["general", "general2", "overlay"]),
  withUnitsSwitch: PropTypes.bool,
  uploadPhotosButton: PropTypes.bool,
  overlay: PropTypes.node,
  galleryOpen: PropTypes.bool,
  onGalleryClose: PropTypes.func,
  regionDetails: PropTypes.shape({
    regionTypeName: PropTypes.string,
    parent: PropTypes.string,
    grandparent: PropTypes.string,
  }),
  pagePath: PropTypes.string,
}
export default RegionHeader
