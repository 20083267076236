import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Tooltip from "@src/components/core-tooltip"
import { DIFFICULTY_RATING_SYMBOLS } from "@src/utils/constants/difficulty-rating-symbols"

const StyledTooltip = styled(Tooltip)`
  ${props =>
    props.size === "small"
      ? css`
          &.rating-double-diamond {
            & > .tooltip-trigger {
              & > .rating-symbol {
                font-size: 7px;
              }
            }
          }
          @media only screen and (${props => props.theme.screen.small.min}) {
            &.rating-double-diamond {
              & > .tooltip-trigger {
                & > .rating-symbol {
                  font-size: 11px;
                }
              }
            }
          }
        `
      : undefined}
`
const DiamondCSS = css`
  content: " ";
  position: absolute;
  left: 0.2071em;
  display: inline-block;
  width: 1em;
  height: 1em;
  transform: rotate(45deg);
`
const DiamondContainerCSS = css`
  position: relative;
  padding-top: 0.2071em;
  height: 1.4142em;
  line-height: 1em;
`
const StyledSymbol = styled.span`
  display: inline-block;
  font-size: ${props => (props.size === "small" ? "12px" : "20px")};

  /* the text is output for accessibility, but visually we want to hide it */
  /* source: https://stackoverflow.com/a/12783507/1798697 */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  ${props =>
    props.symbol !== "double-diamond" && props.symbol !== "diamond"
      ? css`
          width: 1em;
          height: 1em;
        `
      : undefined}

  ${props =>
    props.symbol === "circle"
      ? css`
          border-radius: 50%;
          background-color: ${props => props.theme.difficulty.circleNew};
        `
      : props.symbol === "square"
      ? css`
          background-color: ${props => props.theme.difficulty.square};
        `
      : props.symbol === "diamond"
      ? css`
          ${DiamondContainerCSS}
          width: 1.4142em;
          &::before {
            ${DiamondCSS}
            background-color: ${props => props.theme.difficulty.diamond};
          }
        `
      : props.symbol === "double-diamond"
      ? css`
          ${DiamondContainerCSS}
          width: 2.15em;
          &::before,
          &::after {
            ${DiamondCSS}
            background-color: ${props => props.theme.difficulty.doubleDiamond};
          }
          &::after {
            left: auto;
            right: 0.2071em;
          }
        `
      : undefined}

      @media only screen and (${props => props.theme.screen.small.min}) {
    ${props =>
      props.size === "small" &&
      css`
        font-size: 16px;
      `}
  }
`

const DifficultyRatingSymbol = ({
  className,
  ratingNumber,
  size,
  ...props
}) => {
  const rating = DIFFICULTY_RATING_SYMBOLS.find(
    rating => rating.value === ratingNumber
  )
  return (
    (rating && (
      <StyledTooltip
        className={`${className} rating-${rating.symbol}`}
        size={size}
        triggerElement={
          <StyledSymbol
            className={`rating-symbol`}
            symbol={rating.symbol}
            size={size}
          >
            {rating.title}
          </StyledSymbol>
        }
        triggerProps={props}
      >
        <div className="title">{rating.title}</div>
        <div className="details">{rating.description}</div>
      </StyledTooltip>
    )) ||
    null
  )
}
DifficultyRatingSymbol.propTypes = {
  className: PropTypes.string,
  ratingNumber: PropTypes.number,
  size: PropTypes.oneOf(["small"]),
}
export default DifficultyRatingSymbol
