import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import StickyCollapsePanel from "@src/components/container-panel-sticky-collapse"
import Link from "@src/components/core-link"
import ScrollLink from "@src/components/core-scroll-link"

const StyledLink = styled(Link)``
const StyledScrollLink = styled(ScrollLink)``
const StyledTableOfContents = styled.section`
  max-height: calc(100vh - ${props => props.theme.header.tablet.height}px);

  display: flex;
  flex-direction: column;

  padding: 12px 10px;

  & ${StyledLink}, & ${StyledScrollLink} {
    color: #667085;
    &:hover {
      color: #555555;
    }

    &:active {
      color: #333333;
    }
  }

  & ul {
    list-style-type: none;
    padding: 0;
    display: none;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: center;
  }

  @media only screen and (${props => props.theme.screen.small.max}) {
    & li {
      & ${StyledLink}, & ${StyledScrollLink} {
        margin-right: 10px;
        text-decoration: underline;
      }
      &:last-child {
        padding-right: 30px;
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    max-height: calc(100vh - ${props => props.theme.header.small.height}px);
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    max-height: calc(100vh - ${props => props.theme.header.medium.height}px);

    background-color: ${props => props.theme.bg1};
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 0 0 15px 0;

    & ${StyledLink}, & ${StyledScrollLink} {
      color: #667085;

      &:hover {
      color: #555555;
      }

      &:active {
      color: #333333;
      }
    }

    & ul {
      display: block;
      overflow-y: auto;
      flex: 0 1 auto;
      & li {
        margin-bottom: 12px;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    max-height: calc(100vh - ${props => props.theme.header.large.height}px);
  }
`

const TableOfContents = ({
  children,
  header,
  footer,
  entries,
  scrollOffset,
  collapsed,
  setCollapsed,
  setScrolling,
}) => {
  const [activeScrollTarget, setActiveScrollTarget] = useState(null)
  useEffect(() => {
    if (setCollapsed) {
      setCollapsed(collapsed)
    }
  }, [collapsed, setCollapsed])
  const handleScrollStart = useCallback(() => {
    if (setCollapsed) {
      setCollapsed(true)
    }
    if (setScrolling) {
      setScrolling(true)
    }
  }, [setScrolling, setCollapsed])
  const handleScrollFinish = useCallback(() => {
    if (setScrolling) {
      setScrolling(false)
    }
  }, [setScrolling])
  const handleScrollSetActive = useCallback(
    to => {
      setActiveScrollTarget(to)
    },
    [setActiveScrollTarget]
  )
  const handleScrollSetInactive = useCallback(() => {
    setActiveScrollTarget(null)
  }, [setActiveScrollTarget])

  const renderEntryLink = entry => {
    const linkProps = { title: entry.title }
    if (entry.onClick) {
      linkProps.onClick = entry.onClick
      return <StyledLink {...linkProps}>{entry.title}</StyledLink>
    }
    linkProps.scroll = {
      to: entry.target,
      offset: scrollOffset,
      onStart: handleScrollStart,
      onFinish: handleScrollFinish,
      onSetActive: handleScrollSetActive,
      onSetInactive: handleScrollSetInactive,
    }
    return <StyledScrollLink {...linkProps}>{entry.title}</StyledScrollLink>
  }

  const renderEntriesList = entries => {
    return entries && entries.length ? (
      <ul>
        {entries.map(entry => {
          return (
            <li
              key={entry.target}
              className={`${entry.target} ${
                entry.target && entry.target === activeScrollTarget
                  ? "active"
                  : ""
              }`}
            >
              {renderEntryLink(entry)}
            </li>
          )
        })}
      </ul>
    ) : null
  }

  return (
    <StyledTableOfContents className="table-of-contents" collapsed={collapsed}>
      {header}
      {renderEntriesList(entries)}
      {children}
      {footer}
    </StyledTableOfContents>
  )
}
TableOfContents.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  scrollOffset: PropTypes.number,
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  setScrolling: PropTypes.func,
}

const StickyCollapseTableOfContents = ({
  className,
  children,
  entries,
  collapsed,
  ...props
}) => {
  const [forceCollapse, setForceCollapse] = useState(collapsed)
  const [locked, setLocked] = useState(false)

  return (
    <StickyCollapsePanel
      className={`${className}${
        entries && entries.length ? " has-entries" : ""
      }`.trim()}
      forceCollapse={forceCollapse}
      locked={locked}
      minScroll={100}
      showAfter={-1000}
    >
      <TableOfContents
        setCollapsed={setForceCollapse}
        collapsed={collapsed}
        setScrolling={setLocked}
        entries={entries}
        {...props}
      >
        {children}
      </TableOfContents>
    </StickyCollapsePanel>
  )
}
StickyCollapseTableOfContents.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  collapsed: PropTypes.bool,
}
StickyCollapseTableOfContents.defaultProps = {
  collapsed: true,
}
export default StickyCollapseTableOfContents
