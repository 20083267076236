import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import theme from "@src/styles/theme"
import { LocaleContext } from "@src/context/locale-context"
import DistanceValue from "@src/components/core-value-local-distance"
import {
  convertUnit,
  getUnitEquivalent,
  SYSTEM_NAMES,
} from "@src/utils/distances"
import { formatNumber } from "@src/utils/numbers"

const StyledElevationGraph = styled.div`
  border: 1px solid ${props => props.theme.lightGray};
  width: 100%;
  margin-bottom: 30px;
  @media only screen and (${props => props.theme.screen.tablet.max}) {
    & tspan {
      font-size: 14px;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-bottom: 30px;
  }
`
const StyledTooltip = styled.div`
  background-color: rgba(0.3, 0.3, 0.3, 0.8);
  color: ${props => props.theme.fg2};
  border-radius: 4px;
  padding: 4px 10px;

  & > .distance,
  & > .elevation {
    display: block;
    padding: 8px;
  }
  & > .distance {
    & > span {
      font-weight: 500;
    }
  }
  & > .elevation {
    border-bottom: 1px solid ${props => props.theme.fg2};
  }
`
const ElevationTooltip = ({ payload, label, active, targetSystem }) => {
  if (active) {
    return (
      <StyledTooltip>
        <div className="elevation">
          Elev:{" "}
          <DistanceValue
            value={payload[0].value}
            unit="m"
            decimals={0}
            targetSystem={targetSystem}
          />
        </div>
        <div className="distance">
          Dist:{" "}
          <DistanceValue
            value={parseFloat(label)}
            unit="km"
            decimals={1}
            targetSystem={targetSystem}
          />
        </div>
      </StyledTooltip>
    )
  }
  return null
}
ElevationTooltip.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.number,
  active: PropTypes.bool,
  targetSystem: PropTypes.oneOf([SYSTEM_NAMES.imperial, SYSTEM_NAMES.metric]),
}
const getDistTicks = (elevationData, tickCount = 5) => {
  const ticks = []
  if (elevationData.length && tickCount > 1) {
    const tickInc =
      elevationData[elevationData.length - 1].dist / (tickCount - 1)
    for (let i = 0; i < tickCount - 1; i++) {
      ticks.push(i * tickInc)
    }
    ticks.push(elevationData[elevationData.length - 1].dist)
  }
  return ticks
}
const getMinY = dataMin => dataMin - 100
const getMaxY = (dataMax, minMax = 100.0) => Math.max(dataMax + 100, minMax)

const ElevationGraph = ({
  className,
  elevationData,
  targetSystem: defaultSystem,
  id,
}) => {
  return (
    <StyledElevationGraph id={id} className={className}>
      <LocaleContext.Consumer>
        {({ units: toSystem }) => {
          const targetSystem = toSystem || defaultSystem
          const toUnits = {
            km: getUnitEquivalent("km", targetSystem),
            m: getUnitEquivalent("m", targetSystem),
            mi: getUnitEquivalent("mi", targetSystem),
            ft: getUnitEquivalent("ft", targetSystem),
          }
          return (
            // Aspect fixes MacOS display issues & incorrect responsive behavior on certain browsers: https://stackoverflow.com/questions/47614196/rechart-responsive-container-does-not-render
            <ResponsiveContainer aspect={3}>
              <AreaChart data={elevationData} margin={{ top: 15, right: 10 }}>
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="dist"
                  type="number"
                  scale="linear"
                  name="Distance"
                  tickMargin={4}
                  domain={[0, dataMax => Math.round(dataMax * 100) / 100]}
                  ticks={getDistTicks(elevationData)}
                  tickFormatter={tick =>
                    formatNumber(
                      convertUnit(parseFloat(tick), "km", toUnits.km),
                      1
                    ) +
                    " " +
                    toUnits.km
                  }
                />
                <YAxis
                  dataKey="elev"
                  type="number"
                  scale="linear"
                  name="Elevation"
                  width={80}
                  domain={[
                    dataMin => getMinY(dataMin),
                    dataMax => getMaxY(dataMax),
                  ]}
                  tickFormatter={tick =>
                    formatNumber(
                      convertUnit(parseFloat(tick), "m", toUnits.m),
                      0
                    ) +
                    " " +
                    toUnits.m
                  }
                />
                <Tooltip
                  content={<ElevationTooltip targetSystem={targetSystem} />}
                />
                <Area
                  type="monotone"
                  dataKey="elev"
                  dot={false}
                  activeDot={{
                    fill: theme.primary,
                    stroke: theme.darkGray,
                    r: 5,
                  }}
                  fill={theme.paleYellow}
                  stroke={theme.primary}
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          )
        }}
      </LocaleContext.Consumer>
    </StyledElevationGraph>
  )
}
ElevationGraph.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  elevationData: PropTypes.array,
  targetSystem: PropTypes.oneOf([SYSTEM_NAMES.imperial, SYSTEM_NAMES.metric]),
}
export default ElevationGraph
