import React, { useState } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { scroller } from "react-scroll"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta } from "@src/utils/page-data"
import HeaderRegion from "@src/components/page-header-region-v2"
import ResponsiveColumns from "@src/components/container-responsive-columns"
import Button from "@src/components/core-button"
import RouteDetailList from "@src/components/content-route-details-list"
import ListColumns from "@src/components/core-list-columns"
import TableOfContentsActivity from "@src/components/content-toc-activity"
import ElevationGraph from "@src/components/content-elevation-graph"
import RouteGuideReviews from "@src/components/content-route-guide-reviews"
import {
  ImagePropTypes,
  ImageCreditsPropTypes,
} from "@src/components/core-image"
import { htmlToJsx } from "@src/components/core-value-html"
import TrailStats from "@src/components/content-activity-page-header-trail-details"
import { SITE_DOMAIN } from "@src/utils/constants"
import {
  bestReviewRating,
  worstReviewRating,
} from "@src/utils/constants/structured-data-options"
import { getTwoTupleValueByKey } from "@src/utils/tuples"
import generateLocalBusiness from "@src/utils/structured-data/generateLocalBusiness"
import generateBreadcrumbList from "@src/utils/structured-data/generateBreadcrumbList"
import { useRouteBreadcrumbs } from "@src/hooks/useRouteBreadcrumbs"
import TravelAdvice from "@src/components/travel-advisor-connect"
import CollapsibleContent from "@src/components/core-collapsible-content"
import { SiteSeoLinksContainer } from "@src/components/site-seo-links"
import TrailCardList from "@src/components/trail-card-list"
import CTAGetAppTrailDetails from "@src/components/cta-get-app-trail-details"
// import { TrailsLinksToTours } from "@src/components/trails-links-to-tours"

const svgData = `
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="4" viewBox="0 0 14 4" fill="none">
    <path d="M1 2.5L1.61138 1.83767C2.23637 1.1606 3.33243 1.25616 3.83082 2.03115L3.91746 2.16588C4.45244 2.99776 5.68052 2.96107 6.16488 2.09873L6.23051 1.98189C6.71092 1.12657 7.95771 1.16148 8.41618 2.02875V2.02875C8.86389 2.87565 10.0796 2.9145 10.5554 2.0831V2.0831C10.9864 1.33015 12.0447 1.25816 12.5737 1.9458L13 2.5" stroke="#629D94" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
`;

const svgDataUrl = `data:image/svg+xml,${encodeURIComponent(svgData)}`;

const StyledButton = styled(Button)`
@media only screen and (${props => props.theme.screen.tablet.max}) {
  margin-bottom: 30px;
  width: -webkit-fill-available;
  display: inline-flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #FEC51B;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
`

const CustomSiteSeoLinksContainer = styled(SiteSeoLinksContainer)`
  border-top: none;
  margin-bottom: 85px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: #667085;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Paragraph Text/Body L */
  font-family: system-ui;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
`
const ContentHeader = styled.h2`
  color: #101828;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Headings/Heading5 */
  font-family: system-ui;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 123.077% */
  letter-spacing: 0.5px;
  margin-bottom: 24px;

  &.nearby-trails {
    font-size: 32px;
    line-height: 44px;
  }

  &.nearby-regions {
    font-size: 18px;
    line-height: 24px;
  }

  &.weather-forecast-text {
    margin-bottom: 24px;
  }
`

const StyledDiv = styled.div`
 .newStyle {
  color: #101828;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: system-ui;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 123.077% */
  letter-spacing: 0.5px;
 }
`

const StyledWeatherText = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #FEC51B;
  color: #26383B;
  font-family: system-ui;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.09px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    color: #26383B;
    font-family: system-ui;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.09px;
    gap: 8px;
  }
 
`

const StyledListItem = styled.li`
  padding-bottom: 10px;

  &:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 4px;
    flex-shrink: 0;
    background-image: url('${svgDataUrl}');
    background-repeat: no-repeat;
    padding-right: 8px;
    padding-bottom: 3px;
  }
`

const StyledSection = styled.section`
  margin-top: 0px;
  margin-bottom: 14px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-top: 50px;
  }
`


const PageTemplate = ({ data }) => {
  const [galleryIsOpen, setGalleryIsOpen] = useState(false)

  const {
    wpRoute: {
      routeId,
      title,
      uri,
      // Min is southwest corner & max is north east corner
      gps: { start: gpsStart, cornerSW, cornerNE },
      images: {
        header: headerImage,
        featureOverlayText: headerImageOverlayText,
        featureDummyImage: headerImageDummy,
        map: mapImage,
        mapMobile: mapToC,
        gallery: galleryImages,
      },
      seo: pageSeo,
      activityType: { activityTypeUri, activityTypeName },
      region: { id: regionId, slug: regionSlug, name: regionName },
      summary,
      distance,
      elevation,
      duration,
      difficulty,
      ratings,
      description,
      insiderHints,
      directions,
      weatherUrl,
      routeDetails,
      elevationData,
      reviewsCache: reviewsCacheJSON,
    },
    relatedRoutes: { nodes: relatedRoutes },
    relatedRegions: { nodes: relatedRegions },
  } = data
  const reviewsCache = reviewsCacheJSON ? JSON.parse(reviewsCacheJSON) : null
  const directionCoordinates = gpsStart && `${gpsStart.lat},${gpsStart.lon}`
  const gps = [gpsStart, cornerSW, cornerNE]
  const breadcrumbRegions = useRouteBreadcrumbs(regionId)
  const breadcrumbs = [
    {
      text: activityTypeName,
      link: activityTypeUri,
    },
  ]
  if (breadcrumbRegions && breadcrumbRegions.length) {
    breadcrumbRegions.forEach(region =>
      breadcrumbs.push({
        text: region.text,
        link: `${activityTypeUri}${region.slug}/`,
      })
    )
  }

  const trailDetails = [
    {
      title: "Difficulty",
      tooltip:
        "This reflects the 10Adventures difficulty rating for each route. We aim to keep ratings consistent across regions.",
      icon: "v2-thick-Fire",
      content: difficulty,
    },
    {
      title: "Elevation",
      tooltip:
        "This reflects the total elevation gained throughout this route as measured by the GPS file. This includes all ascents and descents, and is higher than what is quoted in most route guides, which simply measure the distance between the starting-point and high-point of the route.",
      icon: "v2-mountains",
      content: elevation,
    },
    {
      title: "Distance",
      tooltip:
        "This reflects the return distance of this route as measured by the GPS file.",
      icon: "v2-backpack",
      content: distance,
    },
    {
      title: "Duration",
      tooltip:
        "This reflects the estimated time the majority of users will take on this trail. If you are slower, add time to the top-end figure. If you are fast, then you may complete this route faster than this time range.",
      icon: "v2-stopwatch",
      content: duration,
    },
    {
      title: "User Ratings",
      tooltip:
        "These ratings are completed by users who have completed this trail and not subject to reviews by 10Adventures.",
      content: [
        {
          title: "Overall Rating",
          tooltip:
            "This is the average user-submitted overall rating on a scale of 1 to 10, with 10 being the highest.",
          content: ratings.overall?.toFixed(1),
          count: reviewsCache?.total
        },
        {
          title: "Physical DIFF",
          tooltip:
            "This is the average user-submitted rating on the physical difficulty of this route. In general, green is beginner, blue is intermediate, black is advanced/most difficult and double-black is expert-only. It is recommended that users build up to black and double-black routes.",
          content: ratings.physical
        },
        {
          title: "Technical DIFF",
          tooltip:
            "This is the average user-submitted rating on the technical difficulty of this route. In general, green is beginner, blue is intermediate, black is advanced/most difficult and double-black is expert-only. It is recommended that users build up to black and double-black routes.",
          content: ratings.technical
        },
      ],
    },
  ]

  const handleViewImagesClick = event => {
    event.preventDefault()
    setGalleryIsOpen(true)
  }
  const handleGalleryClose = () => {
    setGalleryIsOpen(false)
  }
  const tableOfContentsEntries = []
  const tableOfContentsContent = []
  if (galleryImages && galleryImages.length > 1) {
    tableOfContentsEntries.push({
      target: "view-images",
      title: "View images",
      onClick: handleViewImagesClick,
    })
  }

  if (summary) {
    tableOfContentsContent.push(
      <StyledSection
        key="route-intro"
        id="route-intro"
        className="fancy-lists"
      >
        <StyledDiv>
          {htmlToJsx(summary.replaceAll("<h2>", "<h2 class='newStyle' >"))}
        </StyledDiv>
      </StyledSection>
    )
  }

  if (description) {
    tableOfContentsEntries.push({
      target: "route-description",
      title: "Route description",
    })
    tableOfContentsContent.push(
      <section
        key="route-description"
        id="route-description"
        className="fancy-lists"
        style={{marginBottom: '14px'}}
      >
        <StyledDiv>
          <ContentHeader>{`Route Description for ${title}`}</ContentHeader>
          {htmlToJsx(description.replaceAll("<h2>", "<h2 class='newStyle' >"))}
        </StyledDiv>
      </section>
    )
  }
  if (insiderHints && insiderHints.length) {
    tableOfContentsEntries.push({
      target: "insider-hints",
      title: "Insider hints",
    })
    tableOfContentsContent.push(
      <section key="insider-hints" id="insider-hints" style={{marginBottom: '14px'}}>
        <ContentHeader>{`Insider Hints for ${title}`}</ContentHeader>
        <ul style={{listStyle: "none"}}>
          {insiderHints.map((hint, index) => (
            <StyledListItem key={index}>{htmlToJsx(hint)}</StyledListItem>
          ))}
        </ul>
      </section>
    )
  }
  if (directions) {
    tableOfContentsEntries.push({
      target: "getting-there",
      title: "Getting there",
    })
    tableOfContentsContent.push(
      <section key="getting-there" id="getting-there" style={{marginBottom: '14px'}}>
        <ContentHeader>{`Getting to the ${title} Trailhead`}</ContentHeader>
        {htmlToJsx(directions)}
      </section>
    )
  }
  if (routeDetails && routeDetails.length) {
    tableOfContentsEntries.push({
      target: "route-information",
      title: "Route information",
    })
    tableOfContentsContent.push(
      <section key="route-information" id="route-information">
        <ContentHeader>Route Information</ContentHeader>
        <RouteDetailList
          items={routeDetails.map(detail => ({
            term: detail.label,
            description: detail.value,
            icon: detail.icon,
          }))}
        />
      </section>
    )
  }
  if (elevationData && elevationData.length) {
    tableOfContentsEntries.push({
      target: "elevation-graph",
      title: "Elevation graph",
    })
    tableOfContentsContent.push(
      <section key="elevation-graph" id="elevation-graph">
        <ContentHeader>{`${title} Elevation Graph`}</ContentHeader>
        <ElevationGraph elevationData={elevationData} />
      </section>
    )
  }
  if (weatherUrl) {
    tableOfContentsEntries.push({
      target: "weather",
      title: "Weather forecast",
    })
    tableOfContentsContent.push(
      <section key="weather" id="weather">
        <ContentHeader className="weather-forecast-text">Weather Forecast</ContentHeader>
        <StyledButton
          to={weatherUrl} 
          rel="nofollow"
          style={{ marginBottom: '30px' }}>
          <StyledWeatherText>Check Area Weather</StyledWeatherText>
        </StyledButton>
      </section>
    )
  }

  const handleReviewAdded = () => {
    scroller.scrollTo("reviews", { offset: -125 })
  }
  tableOfContentsEntries.push({
    target: "reviews",
    title: "Reviews",
  })
  tableOfContentsContent.push(
    <section key="reviews" id="reviews">
      <ContentHeader>Reviews</ContentHeader>
      <RouteGuideReviews
        baseKey={`route-${routeId}-reviews`}
        routeId={routeId}
        initReviews={reviewsCache}
        onReviewAdded={handleReviewAdded}
      />
    </section>
  )

  const relatedRegionsUpdatedURI = relatedRegions && relatedRegions.map(region => ({
    title: region.name,
    link:
      (region.activityTypePrimary && region.activityTypePrimary.uri) ||
      region.uri,
  }));

  GTM.dataLayerPushPageInfo({
    template: "activity-single",
  })
  
  const rawStructuredData = {
    name: title,
    parentName: regionName,
    description: pageSeo.metaDesc,
    ratings: reviewsCache &&
      ratings.overall && {
        // Required - name, value, count
        name: "Overall Rating",
        value: ratings.overall,
        count: reviewsCache.total,
        best: bestReviewRating,
        worst: worstReviewRating,
        // Required for user reviews
        reviews:
          reviewsCache.items?.length &&
          reviewsCache.items.map(item => {
            return {
              text: item.text,
              user: item.user,
              time: item.time,
              overallRating:
                item.ratings.length &&
                getTwoTupleValueByKey(item.ratings, "overall"),
              best: bestReviewRating,
              worst: worstReviewRating,
            }
          }),
      },
    image: headerImage && headerImage.src,
    coordinates: gpsStart,
  }
  const rawBreadcrumbStructuredData = breadcrumbs.map((breadcrumb, index) => {
    return {
      name: breadcrumb.text,
      url: `${SITE_DOMAIN}${breadcrumb.link}`,
      position: index + 1,
    }
  })

  return (
    <PrimaryLayout showSeo={false}>
      <PageHelmet
        title={normalizeTitle(pageSeo.title || pageSeo.opengraphTitle)}
        meta={generateMeta(uri, { ...pageSeo, metaRobotsNoindex: "index" })}
        structuredData={[
          generateLocalBusiness(rawStructuredData),
          generateBreadcrumbList(rawBreadcrumbStructuredData),
        ]}
      />
      <HeaderRegion
        title={title}
        breadcrumbs={breadcrumbs}
        image={headerImage}
        imageOverlayText={headerImageOverlayText}
        imageDummy={headerImageDummy}
        map={mapImage}
        gallery={galleryImages}
        mapPath={regionSlug}
        gps={gps}
        content={summary}
        withUnitsSwitch={false}
        galleryOpen={galleryIsOpen}
        onGalleryClose={handleGalleryClose}
        overlay={
          <TrailStats
            trailDetails={trailDetails}
            directions={directionCoordinates}
          />
        }
        variant="overlay"
      />
      <ResponsiveColumns>
          <TableOfContentsActivity
            title="Table of contents"
            image={mapToC}
            mapPath={regionSlug}
            gps={gps}
            entries={tableOfContentsEntries}
          />
        <Content>{tableOfContentsContent}</Content>
      </ResponsiveColumns>
      <TravelAdvice title={title}/>
      {relatedRoutes && relatedRoutes.length > 0 && (
        <TrailCardList trails={relatedRoutes}/>
      )}
      {relatedRegionsUpdatedURI && relatedRegionsUpdatedURI.length > 0 && (
        <CustomSiteSeoLinksContainer align="left">
          <CollapsibleContent
              header={
                <ContentHeader className="nearby-regions">
                  Nearby Regions
                </ContentHeader>
              }
              content={
                <section id="nearby-regions">
                  <ListColumns items={relatedRegionsUpdatedURI} variant="compact" />
                </section>
              }
              className="nearby-regions"
              icon="v2-angle-down"
          />
        </CustomSiteSeoLinksContainer>
      )}
      <CTAGetAppTrailDetails/>
      {/* <TrailsLinksToTours/> */}
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    wpRoute: PropTypes.shape({
      routeId: PropTypes.number,
      title: PropTypes.string,
      uri: PropTypes.string,
      gps: PropTypes.shape({
        start: PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        }),
        cornerSW: PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        }),
        cornerNE: PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        }),
      }),
      images: PropTypes.shape({
        header: PropTypes.shape({ ...ImagePropTypes }),
        featureDummyImage: PropTypes.string,
        featureOverlayText: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
          })
        ),
        map: PropTypes.shape({ ...ImagePropTypes }),
        mapMobile: PropTypes.shape({ ...ImagePropTypes }),
        gallery: PropTypes.arrayOf(
          PropTypes.shape({
            ...ImagePropTypes,
            credits: PropTypes.shape({ ...ImageCreditsPropTypes }),
          })
        ),
      }),
      seo: PropTypes.shape({ ...PageSeoPropTypes }),
      activityType: PropTypes.shape({
        activityTypeUri: PropTypes.string,
        activityTypeName: PropTypes.string,
      }),
      region: PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        name: PropTypes.string,
      }),
      summary: PropTypes.string,
      structuredDescription: PropTypes.string,
      distance: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
      }),
      elevation: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
      }),
      duration: PropTypes.string,
      difficulty: PropTypes.string,
      ratings: PropTypes.shape({
        overall: PropTypes.number,
        physical: PropTypes.number,
        technical: PropTypes.number,
        count: PropTypes.number
      }),
      description: PropTypes.string,
      insiderHints: PropTypes.arrayOf(PropTypes.string),
      directions: PropTypes.string,
      weatherUrl: PropTypes.string,
      routeDetails: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          icon: PropTypes.string,
        })
      ),
      elevationData: PropTypes.arrayOf(
        PropTypes.shape({
          dist: PropTypes.number,
          elev: PropTypes.number,
        })
      ),
      reviewsCache: PropTypes.string,
    }),
    relatedRoutes: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    }),
    relatedRegions: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          uri: PropTypes.string,
          activityTypePrimary: PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            routeCount: PropTypes.number,
          }),
        })
      ),
    }),
  }),
}
export default PageTemplate

export const query = graphql`
  query ($id: String, $nearbyRouteIds: [String], $nearbyRegionIds: [String]) {
    wpRoute(id: { eq: $id }) {
      routeId: databaseId
      title
      uri
      gps {
        start {
          lat
          lon
        }
        cornerSW: min {
          lat
          lon
        }
        cornerNE: max {
          lat
          lon
        }
      }
      images {
        header {
          ...WpImageSizesQuery
        }
        featureDummyImage
        featureOverlayText {
          key
          value
        }
        map {
          ...WpImageSizesQuery
        }
        mapMobile {
          ...WpImageSizesQuery
        }
        gallery {
          ...WpImageSizesQuery
          ...WpImageCreditsQuery
        }
      }
      seo {
        ...WpPostSeoQuery
      }
      activityType {
        activityTypeUri: uri
        activityTypeName: name
      }
      region {
        id: databaseId
        slug
        name
      }
      summary
      distance {
        value
        unit
      }
      elevation {
        unit
        value
      }
      duration
      difficulty
      ratings {
        overall
        physical
        technical
      }
      description
      insiderHints: hints
      directions
      weatherUrl
      routeDetails: routeAttributes {
        label
        value
        icon
      }
      elevationData {
        dist
        elev
      }
      reviewsCache
    }
    relatedRoutes: allWpRoute(filter: { id: { in: $nearbyRouteIds } }) {
      nodes {
        title
        link: uri
        images {
          header {
            ...WpImageSizesQuery
          }
          featureDummyImage
        }
        region {
          id: databaseId
          slug
          name
        }
        distance {
          value
          unit
        }
        elevation {
          unit
          value
        }
        duration
        difficulty
      }
    }
    relatedRegions: allWpRegion(filter: { id: { in: $nearbyRegionIds } }) {
      nodes {
        name
        uri
        ...wpRegionActivityTypePrimary
      }
    }
  }
`
