import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import DifficultyRatingSymbol from "@src/components/core-value-difficulty-rating-symbol"
const StyledDifficultyRatingSymbol = styled(DifficultyRatingSymbol)`
  & .rating-symbol {
    font-size: 16px;
  }
  &:not(:hover):not(.active) {
    &.rating-circle .rating-symbol,
    &.rating-square .rating-symbol,
    &.rating-diamond .rating-symbol::before,
    &.rating-double-diamond .rating-symbol::before,
    &.rating-double-diamond .rating-symbol::after {
      background-color: ${props => props.theme.lightGray};
    }
  }
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -0.25em;
  & ${StyledDifficultyRatingSymbol} {
    margin: 0 0.25em;
  }
`
const min = 0
const max = 4
const InputRatingDifficultySymbols = ({
  className,
  id,
  name,
  value: defaultValue,
  onChange,
}) => {
  const [value, setValue] = useState(null)
  const updateValue = useCallback(
    newValue => {
      if (newValue < min) {
        newValue = min
      } else if (newValue > max) {
        newValue = max
      }
      setValue(newValue)
      if (onChange) {
        onChange(newValue)
      }
    },
    [setValue, onChange]
  )
  useEffect(() => {
    if (updateValue) {
      updateValue(defaultValue)
    }
  }, [defaultValue, updateValue])
  return (
    <InputWrapper
      className={`${className} ${value ? " has-value" : ""}`}
      id={id}
    >
      {(value || value === 0) && (
        <input type="hidden" name={name} value={value} />
      )}
      {[...Array(max)].map((e, i) => (
        <StyledDifficultyRatingSymbol
          key={`${id}-difficulty-rating-${i + 1}`}
          ratingNumber={i + 1}
          className={i + 1 === value ? "active" : undefined}
          onClick={() => {
            updateValue(i + 1)
          }}
        />
      ))}
    </InputWrapper>
  )
}
InputRatingDifficultySymbols.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
}
export default InputRatingDifficultySymbols
