import axios from "axios"
import Auth from "@src/services/user/auth"
import { formatNumber } from "@src/utils/numbers"
import { awsBasePath } from "./_config"

const RouteReviewsService = {
  getRouteReviews: async (routeId, params) =>
    axios
      .get(`${awsBasePath}/route/${routeId}/reviews`, params)
      .then(response => {
        if (response && response.data) {
          return response.data
        }
        return null
      })
      .catch(error => console.log("Error in getRouteReviews request: ", error)),

  createRouteReview: async (routeId, values) => {
    // structure data for the AWS endpoint
    const submitData = {
      route_id: routeId,
      review_details: {
        review_text: values["review-content"] || values["review-title"],
        content: {
          title: values["review-title"],
        },
      },
    }
    const ratings = {}
    for (const ratingKey in values.ratings) {
      // only include ratings that are actually set
      if (values.ratings[ratingKey]) {
        ratings[ratingKey] = values.ratings[ratingKey]
      }
    }
    if (Object.keys(ratings).length) {
      submitData.review_details.ratings = ratings
    }

    const timeHours = parseInt(values["time-taken"]["hours"]) || 0
    const timeMinutes = parseInt(values["time-taken"]["minutes"]) || 0
    if (timeHours || timeMinutes) {
      // prettier-ignore
      submitData.review_details.content["tripDuration"] = `${
          formatNumber(timeHours, 0, "", 2 )
        }:${
          formatNumber(timeMinutes, 0, "", 2)
        }`
    }

    // get the user's token to use for authorization
    const userToken = await Auth.getUserToken()

    return axios.post(`${awsBasePath}/route/${routeId}/reviews`, submitData, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
  },
}
export default RouteReviewsService
