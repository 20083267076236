import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Link from "@src/components/core-link"
const Columns = styled.ul`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  list-style-type: none;
  display: flex;
  flex-flow: column wrap;

  @media only screen and (${props => props.theme.screen.tablet.min}) {
    flex-flow: row wrap;
    ${props =>
      props.center
        ? css`
            justify-content: center;
          `
        : ""}
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 21px;
    line-height: 25px;
  }
`
const Item = styled.li`
  padding: ${props =>
    !props.$variant === "compact" ? "0 10px 0 0" : "5px 10px 5px 0"};

  @media only screen and (${props => props.theme.screen.tablet.min}) {
    flex: 1 0 368px;
    max-width: 33%;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    ${props => !props.$variant === "compact" && "padding: 10px 20px 10px 0;"}
    flex: 1 0 241px;
    max-width: 33%;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    flex: 1 0 320px;
    max-width: 25%;
  }
`
const ListColumns = ({ items, variant, center, className }) => {
  const Items =
    items &&
    items.map((item, index) => {
      const ItemContent =
        item.link && item.title ? (
          <Link to={item.link} variant={variant}>
            {item.title}
          </Link>
        ) : item.content ? (
          item.content
        ) : item.title ? (
          item.title
        ) : undefined
      return (
        ItemContent && (
          <Item
            key={`item-${index}-${item.id || item.link || item.title}`}
            variant={variant}
          >
            {ItemContent}
          </Item>
        )
      )
    })
  return (
    <Columns
      center={center && Items.length < 4}
      variant={variant}
      className={className}
    >
      {Items}
    </Columns>
  )
}
ListColumns.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.shape({
        title: PropTypes.string,
        link: PropTypes.string,
      }),
    ])
  ),
  variant: PropTypes.oneOf(["compact"]),
  center: PropTypes.bool,
  className: PropTypes.string,
}
ListColumns.defaultProps = {
  center: true,
}
export default ListColumns
