import React, { useRef, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import RouteReviewsService from "@src/services/reviews/route-reviews"
import DifficultyRating from "@src/components/core-value-difficulty-rating-symbol"
import Button from "@src/components/core-button"
import { formatDate, parseDateISO } from "@src/utils/dates"
import AddRouteGuideReviewModal from "./content-route-guide-review-modal"

const ReviewsWrapper = styled.div`
  & > button {
    margin-bottom: 48px;
    margin-top: 8px;
    border: 1px solid #26383B;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > button {
      max-width: 214px;
      margin-bottom: 56px;
      margin-top: none;
    }
  }  
`
const StyledDifficultyRating = styled(DifficultyRating)``
const TripDetails = styled.dl`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 120px));
  gap: 5px;
  background-color: ${props => props.theme.white};
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  dd {
    order: 1;
    font-weight: 700;
    margin-right: 5px;
  }
  dt {
    order: 2;
    font-weight: 500;
    font-size: 0.625em;
  }
  & > .review-tech-diff,
  & > .review-phys-diff {
    grid-row: 2 / 3;
  }
  .rating-symbol {
    font-size: 1em;
  }
  .review-rating-value {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: system-ui;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.25px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    display: flex;
    flex-direction: row;
    & > .review-duration {
      order: 4;
    }
    dt {
      margin-right: 10px;
    }
  }
`
const ReviewDetails = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
  & > .review-author {
    font-size: 0.875rem;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Subheadings/Subhead 4 */
    font-family: system-ui;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 150% */
    letter-spacing: 0.25px;
  }
  & > .review-date {
    color: ${props => props.theme.reviewDateGray};
    font-size: 0.75rem;
    margin-left: 5px;
    color: #737373;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Paragraph Text/Body S */
    font-family: system-ui;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    letter-spacing: 0.5px;
  }
`
const StyledReview = styled.div`
  margin-bottom: 16px;
  background-color: ${props => props.theme.paleGray};
  border: 1px solid ${props => props.theme.borderGray};
  border-radius: 8px;
  & > * {
    padding-left: 16px;
    padding-right: 16px;
  }
  & > ${ReviewDetails} {
    padding-top: 10px;
  }
  & > ${TripDetails} {
    padding-top: 17px;
    padding-bottom: 17px;
    border-top: 1px solid ${props => props.theme.borderGray};
    border-radius: 0 0 8px 8px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-bottom: 24px;
  }
`
const RouteGuideReviews = ({
  baseKey,
  routeId,
  initReviews,
  onReviewAdded,
}) => {
  const reviewsLoaded = useRef({ items: [], lastPage: 0 })
  const [reviews, setReviews] = useState([])
  const [hasMoreReviews, setHasMoreReviews] = useState(false)
  const processReviewsData = useCallback(
    (reviewsData, reset) => {
      if (reset) {
        reviewsLoaded.current.items = reviewsData.items || []
      } else if (reviewsData.items && reviewsData.items.length) {
        reviewsLoaded.current.items = reviewsLoaded.current.items.concat(
          reviewsData.items
        )
      }
      reviewsLoaded.current.lastPage = reviewsData.page
      setReviews(reviewsLoaded.current.items)
      setHasMoreReviews(
        reviewsData.page < Math.ceil(reviewsData.total / reviewsData.perPage)
      )
    },
    [setReviews, setHasMoreReviews, reviewsLoaded]
  )
  const loadMoreReviews = useCallback(
    reset => {
      RouteReviewsService.getRouteReviews(routeId, {
        page: reset ? 1 : reviewsLoaded.current.lastPage + 1,
      }).then(reviewsData => {
        if (reviewsData) {
          processReviewsData(reviewsData, reset)
        }
      })
    },
    [routeId, processReviewsData, reviewsLoaded]
  )
  const handleLoadMoreReviews = useCallback(() => {
    loadMoreReviews()
  }, [loadMoreReviews])
  useEffect(() => {
    if (initReviews) {
      processReviewsData(initReviews, true)
    }
    loadMoreReviews(true)
  }, [initReviews, processReviewsData, loadMoreReviews])

  const handleReviewAdded = useCallback(() => {
    loadMoreReviews(true)
    if (onReviewAdded) {
      onReviewAdded()
    }
  }, [loadMoreReviews, onReviewAdded])

  const getOverallRating = (reviews) => {
    let numberOfReviews = 0;
    let sum = 0;
  
    reviews.forEach(review => {
      const overallRating = review.ratings.find(rating => rating[0] === 'overall');
      if (overallRating && overallRating[1]) {
        const currentRating = overallRating[1];
        sum += Number(currentRating);
        numberOfReviews++;
      }
    });
  
    const totalReviews = reviews.length;
    const average = numberOfReviews > 0 ? (sum / numberOfReviews).toFixed(1) : "0.0";
  
    return { average, totalReviews };
  };
  const overallRatingInfo = getOverallRating(reviews);

  return (
    <ReviewsWrapper>
      <AddRouteGuideReviewModal
        routeId={routeId}
        onReviewAdded={handleReviewAdded}
        overallRating={overallRatingInfo.average}
        totalReviews={overallRatingInfo.totalReviews}
      />
      {reviews.map((item, index) => {
        const review = {
          content: item.text || "",
        }
        if (item.time) {
          review.date = parseDateISO(item.time)
        }
        if (item.user && item.user.name) {
          review.accountName = item.user.name
        }
        if (item.content && item.content.length) {
          // This for loop might not be necessary. Left in for redundancy in case all cases haven't been validated.
          for (const content of item.content) {
            if (content[0] === "tripDuration") {
              review.tripDuration = content[1]
            }
          }
        }
        if (item.ratings && item.ratings.length) {
          for (const rating of item.ratings) {
            switch (rating[0]) {
              case "overall":
                review.rating = parseFloat(rating[1])
                break
              case "physical":
                review.physDiff = parseFloat(rating[1])
                break
              case "technical":
                review.techDiff = parseFloat(rating[1])
                break
            }
          }
        }
        return (
          <StyledReview key={`${baseKey}-${index}`}>
            <ReviewDetails>
              <span className="review-author">{review.accountName}</span>
              {review.date ? (
                <span className="review-date">
                  {formatDate(review.date, "MMMM yyyy")}
                </span>
              ) : null}
            </ReviewDetails>
            <p>{review.content}</p>
            {review.rating ||
            review.techDiff ||
            review.physDiff ||
            review.tripDuration ? (
              <TripDetails>
                {review.rating && (
                  <div className="review-rating">
                    <dt>Overall Rating</dt>
                    <dd className="review-rating-value">{review.rating}</dd>
                  </div>
                )}
                {review.tripDuration && (
                  <div className="review-duration">
                    <dt>Trip Length</dt>
                    <dd>{review.tripDuration}</dd>
                  </div>
                )}
                {review.techDiff && (
                  <div className="review-tech-diff">
                    <dt>Technical Difficulty</dt>
                    <dd>
                      <StyledDifficultyRating ratingNumber={review.techDiff} />
                    </dd>
                  </div>
                )}
                {review.physDiff && (
                  <div className="review-phys-diff">
                    <dt>Physical Difficulty</dt>
                    <dd>
                      <StyledDifficultyRating ratingNumber={review.physDiff} />
                    </dd>
                  </div>
                )}
              </TripDetails>
            ) : null}
          </StyledReview>
        )
      })}
      {hasMoreReviews && (
        <Button 
          onClick={handleLoadMoreReviews}
          color="outline-black"
          size="full-width-large"
          >
          See More
        </Button>
      )}
    </ReviewsWrapper>
  )
}
RouteGuideReviews.propTypes = {
  baseKey: PropTypes.string,
  routeId: PropTypes.number,
  initReviews: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        date: PropTypes.instanceOf(Date),
        accountName: PropTypes.string,
        content: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        ratings: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      })
    ),
    page: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
  }),
  onReviewAdded: PropTypes.func,
}
RouteGuideReviews.defaultProps = {
  baseKey: "reviews",
}
export default RouteGuideReviews
