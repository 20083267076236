import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import DifficultyRating from "@src/components/core-value-difficulty-rating"
import DifficultyRatingSymbol from "@src/components/core-value-difficulty-rating-symbol"
import Tooltip from "@src/components/core-tooltip"
import Icon from "@src/components/core-icon"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"
import Distance from "@src/components/core-value-local-distance"
import ScrollLink from "@src/components/core-scroll-link"
import Link from "@src/components/core-link"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"

const RouteIconDetail = styled(IconDetailContentTile)`
  display: flex;
  font-weight: 500;
  white-space: nowrap;
  align-items: center;
  & .icon {
    font-size: 16px;
  }
`

const ReviewScrollLink = styled(ScrollLink)`
  text-decoration: underline;
  font-weight: 500;
  font-size: 0.75rem;
  width: fit-content;
  padding-bottom: 24px;
  @media only screen and (${props => props.theme.screen.medium.min}) {
    padding-bottom: 0px;
  }
`
const DetailTitle = styled.span`
  display: flex;
  font-weight: 500;
  color: #757575;
  font-family: system-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.14px;
`
const DetailSectionTitle = styled.span`
  display: flex;
  font-weight: 700;
  text-align: center;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* Buttons/Button M */
  font-family: system-ui;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`
const DetailValue = styled.div`
  font-weight: 700;
  display: flex;
  color: #3a6968;
  font-feature-settings: "clig" off, "liga" off;

  /* Buttons/Button M */
  font-family: system-ui;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: 54px;
  height: 21px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`
const TrailStats = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-row-gap: 15px;
  padding: 24px 0;

  & > ${RouteIconDetail} {
    justify-content: center;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-top: 1px solid ${props => props.theme.borderGray};
    border-bottom: 1px solid ${props => props.theme.borderGray};
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    padding: 0;
    border: none;
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    padding: 0;
    border: none;
  }
`
const UserRatings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.$empty
      ? css`
          & a {
            margin-top: 40px;
          }
        `
      : ""}
  @media only screen and (${props => props.theme.screen.small.min}) {
    ${props =>
      props.$empty
        ? css`
            & a {
              margin-top: 30px;
            }
          `
        : ""}
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    border: none;
    ${props =>
      props.$empty
        ? css`
            & a {
              margin-top: 30px;
            }
          `
        : ""}
  }
`
const TrailReviews = styled.dl`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`
const MapLogoWrapper = styled(ImageWrapper)`
  height: 40px;
  width: 40px;
`
const MapApps = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 33px;
  padding-bottom: 24px;
  & > a {
    text-decoration: none;
    font-weight: 500;
    font-size: 0.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 5px;
    & > div {
      margin-right: 7px;
    }
    &:first-child {
      margin-bottom: 3px;
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    flex-direction: row;
    padding-bottom: 0px;
    gap: 0px;
    & > a {
      flex-direction: column;
      & > div {
        margin: 0 0 5px 0;
      }
      &:first-child {
        margin: 0 35px 0 0;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    padding-bottom: 0px;
    gap: 0px;
    & > a {
      & > div {
        margin-right: 0;
      }
      &:first-child {
        margin: 0 15px 0 0;
      }
    }
  }
`
const Directions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TrailDetailsContainer = styled.div`
  display: grid;
  padding: 0px 38px;
  background: #fff;

  & > ${TrailStats} {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    border-bottom: 1px solid ${props => props.theme.borderGray};
  }
  & > ${Directions} {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    border-bottom: 1px solid ${props => props.theme.borderGray};
    padding-top: 24px;
  }
  & > ${UserRatings} {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    padding-top: 24px;
  }

  & .tooltip-container {
    white-space: normal;
    font-size: 0.8125rem;
    font-weight: normal;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    padding: 20px 0;
    border: none;
    & > ${TrailStats} {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      border: none;
      padding: 0px;
    }
    & > ${Directions} {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      border: none;
      padding: 0px;
    }
    & > ${UserRatings} {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      padding: 0px;
    }
    & > ${TrailStats}, & > ${Directions} {
      border-right: 1px solid ${props => props.theme.borderGray};
    }
  }
`

const AppStoreText = styled.div`
  color: #757575;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.06px;
  text-decoration: underline;
  text-underline-position: under;
`
const StyledIcon = styled(Icon)`
  margin: 3px;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  color: #757575;
`

const ParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;

  &.lower-panel {
    margin-top: 16px;
    gap: 24px;
  }
`

const RatingText = styled.div`
  color: #757575;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 150% */
  letter-spacing: 0.07px;
  text-decoration-line: underline;
`

const DifficultyDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
`

const DifficultyText = styled.div`
  color: #757575;
  text-align: center;
  font-family: system-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.14px;
`

const DifficultyIcon = styled(DifficultyRatingSymbol)`
  justify-content: center;
`

const renderTrailIconDetail = (trailDetails, value) => {
  return (
    <Details key={trailDetails}>
      <DetailTitle>
        {trailDetails.title}
        <Tooltip
          triggerElement={<StyledIcon glyph="v2-info" iconFontSize="13px" />}
        >
          {trailDetails.tooltip}
        </Tooltip>
      </DetailTitle>
      <RouteIconDetail
        key={trailDetails.title}
        glyph={trailDetails.icon || ""}
        color={"teal"}
      >
        <DetailValue>{value}</DetailValue>
      </RouteIconDetail>
    </Details>
  )
}

const renderOverallRatingDiv = trail => {
  return (
    <ParentDiv>
      <Icon glyph="star" primary iconFontSize="20px" />
      <ScrollLink scroll={{ to: "reviews" }}>
        <RatingText>
          {trail.content + " (" + trail.count + " reviews)"}
        </RatingText>
      </ScrollLink>
    </ParentDiv>
  )
}

const renderUserRatingDetail = trails => {
  return (
    <ParentDiv className="lower-panel">
      {trails.map(trail => {
        if (trail.title !== "Overall Rating") {
          return (
            <DifficultyDiv key={trail.title}>
              <DifficultyIcon
                ratingNumber={Math.round(trail.content)}
                size="small"
              />
              <DifficultyText>{trail.title}</DifficultyText>
            </DifficultyDiv>
          )
        }
        return null
      })}
    </ParentDiv>
  )
}

const renderTrailDetails = trailDetails => {
  const trailStatisticNodes = []
  const trailRatingNode = []

  trailDetails.forEach(detail => {
    const { title, tooltip, content } = detail

    let render
    let renderedValue
    switch (title) {
      case "Difficulty":
        render = !!content
        renderedValue = render ? (
          <DifficultyRating ratingNumber={Number(content)} />
        ) : (
          "Not Available"
        )
        trailStatisticNodes.push(renderTrailIconDetail(detail, renderedValue))
        break
      case "Duration":
        render = !!content
        renderedValue = <span>{render ? content : "Not Available "}</span>
        trailStatisticNodes.push(renderTrailIconDetail(detail, renderedValue))
        break
      case "Distance":
        render = !!content?.value
        renderedValue = render ? (
          <Distance
            value={content.value && content.value}
            unit={content && content.unit}
            decimals={1}
          />
        ) : (
          <span>No Data</span>
        )
        trailStatisticNodes.push(renderTrailIconDetail(detail, renderedValue))
        break
      case "Elevation":
        render = !!content?.value
        renderedValue = render ? (
          <Distance
            value={content && content.value}
            unit={content && content.unit}
          />
        ) : (
          <span>No Data</span>
        )
        trailStatisticNodes.push(renderTrailIconDetail(detail, renderedValue))
        break
      case "User Ratings":
        render = !!content.filter(ratingType => ratingType.content).length
        trailRatingNode.push(
          <UserRatings key="User Ratings" $empty={!render}>
            <DetailSectionTitle>
              {title}
              <Tooltip
                triggerElement={
                  <Icon
                    glyph="v2-info"
                    style={{ fontSize: "13px", margin: "6.5px auto auto 5px" }}
                  />
                }
              >
                {tooltip}
              </Tooltip>
            </DetailSectionTitle>
            {render ? (
              <>
                <TrailReviews>
                  {renderOverallRatingDiv(
                    content.filter(
                      rating => rating.title === "Overall Rating"
                    )[0]
                  )}
                  {renderUserRatingDetail(content)}
                </TrailReviews>
              </>
            ) : (
              <ReviewScrollLink scroll={{ to: "reviews" }}>
                Be the first to add a review!
              </ReviewScrollLink>
            )}
          </UserRatings>
        )
        break
    }
  })
  return (
    <>
      <TrailStats>{trailStatisticNodes}</TrailStats>
      {trailRatingNode}
    </>
  )
}

const TrailDetails = ({ trailDetails, directions }) => {
  return (
    <TrailDetailsContainer>
      {renderTrailDetails(trailDetails)}
      <Directions>
        <DetailSectionTitle>Directions to Trailhead</DetailSectionTitle>
        <MapApps>
          <Link
            to={`https://www.google.com/maps/dir/?api=1&destination=${directions}`}
          >
            <Image
              src="/icons/google-maps.svg"
              alt="Google maps icon"
              wrapper={MapLogoWrapper}
            />
            <AppStoreText>Google Maps</AppStoreText>
          </Link>

          <Link to={`http://maps.apple.com/?daddr=${directions}`}>
            <Image
              src="/icons/apple-maps.svg"
              alt="Apple maps icon"
              wrapper={MapLogoWrapper}
            />
            <AppStoreText>Apple Maps</AppStoreText>
          </Link>
        </MapApps>
      </Directions>
    </TrailDetailsContainer>
  )
}

TrailDetails.propTypes = {
  trailDetails: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      tooltip: PropTypes.string,
      icon: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ value: PropTypes.number, unit: PropTypes.string }),
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            tooltip: PropTypes.string,
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
      ]),
    })
  ),
  directions: PropTypes.string,
}

export default TrailDetails
