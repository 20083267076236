import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CarouselContainer from "@src/components/container-carousel"
import FeaturedTile from "@src/components/core-featured-tile"

const StyledCarousel = styled(CarouselContainer)`
  & > button {
    margin-top: -10px;
  }
  & > .carousel-content {
    padding-bottom: 5px;
  }
`

const FeaturedTripPlanners = ({ content, ...props }) => {
  const Items =
    content &&
    content.map(({ primary, ...item }) => (
      <FeaturedTile
        key={item.id || item.link || item.title}
        content={item}
        variant={primary ? "button-overlay" : "text-ribbon"}
      />
    ))

  return <StyledCarousel {...props}>{Items}</StyledCarousel>
}
FeaturedTripPlanners.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.object,
      link: PropTypes.string,
      primary: PropTypes.bool,
    })
  ),
}
export default FeaturedTripPlanners
