/*
ReviewSnippets & AggregateRating:
  - Google: https://developers.google.com/search/docs/advanced/structured-data/review-snippet#json-ld
  - Schema.org: https://schema.org/UserReview
*/
const generateUserReview = review => {
  const structuredData = {
    "@type": "UserReview",
    author: {
      "@type": "Person",
      name: review.user.name,
    },
    reviewBody: review.text,
    datePublished: review.time,
  }
  if (review.overallRating) {
    structuredData["reviewRating"] = {
      "@type": "Rating",
      ratingValue: review.overallRating,
      worstRating: review.worst,
      bestRating: review.best,
    }
  }
  return structuredData
}

export default generateUserReview
