import PropTypes from "prop-types"
import { DIFFICULTY_RATINGS } from "@src/utils/constants/difficulty-ratings"
const DifficultyRating = ({ ratingNumber }) => {
  const rating = DIFFICULTY_RATINGS.find(
    rating => rating.value === ratingNumber
  )
  return (rating && rating.title) || null
}
DifficultyRating.propTypes = {
  ratingNumber: PropTypes.number,
}
export default DifficultyRating
