import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { htmlToJsx } from "@src/components/core-value-html"
import { FancyLink } from "@src/styles/elements"

const RouteDetails = styled.ul`
  list-style-type: none;
  margin-bottom: 30px;
  & > li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`
const TextStyles = css`
  line-height: 1.125em;
  margin: 0;
`
const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #667085;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Paragraph Text/Body L */
  font-family: system-ui;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;

  .subtext {
    align-self: self-end;
    color: #3A6968;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Buttons/Button L */
    font-family: system-ui;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  & > .route-detail-title {
    ${TextStyles}
    font-weight: 500;
    margin-right: 5px;
  }
  & > .subtext {
    ${TextStyles}
    & > a {
      ${FancyLink}
      padding: 0;
    }
  }
`

const RouteDetailList = ({ items }) => (
  <RouteDetails>
    {items.map((item, index) => {
      return (
        <li key={index}>
          {/* <StyledIconDetailContentTile glyph={item.icon} color="blue-black"> */}
            <TextContent>
              <p className="route-detail-title">{item.term}:</p>
              <p className="subtext">{htmlToJsx(item.description)}</p>
            </TextContent>
          {/* </StyledIconDetailContentTile> */}
        </li>
      )
    })}
  </RouteDetails>
)

RouteDetailList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
}
export default RouteDetailList
