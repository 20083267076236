import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ImageWrapper from "@src/components/core-image-wrapper"

const StyledDummyImageWrapper = styled(ImageWrapper)`
  &::before {
    font-size: 1.5rem;
  }
  &::after {
    font-size: 1.25rem;
  }
  &.dummy-3 {
    &::before {
      font-size: 1.25rem;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    &::before {
      font-size: 2rem;
    }
    &.dummy-3 {
      &::before {
        font-size: 2rem;
      }
    }
  }
`

const DummyImageWrapper = ({ className, dummyName, ...props }) => {
  return (
    <StyledDummyImageWrapper
      className={`${className} dummy-image ${dummyName}`}
      {...props}
    />
  )
}
DummyImageWrapper.propTypes = {
  className: PropTypes.string,
  dummyName: PropTypes.string,
}
export default DummyImageWrapper
