export const ROUTE_TO_TOUR_BACKLINKS = {
  "/hikes/amalfi-coast/": {
    baseUrl: "",
    link: "https://www.10adventures.com/tours/amalfi-coast-tours-hiking-tours/",
    buttonText: "Walking tours in the Amalfi Coast",
  },
  "/hikes/innsbruck/": {
    link: "https://www.10adventures.com/tours/austria-hiking-tours/",
    buttonText: "Explore Hiking Tours in Austria",
  },
  "/hikes/chamonix/": {
    link: "https://www.10adventures.com/tours/french-alps-tours-hiking-tours/",
    buttonText: "Find hiking tours in the French Alps",
  },
  "/backpacking/glacier-national-park/": {
    link: "https://www.10adventures.com/tours/united-states-hiking-tours/",
    buttonText: "Find backpacking tours in the US",
  },
  "/hikes/north-york-moors-national-park/": {
    link: "https://www.10adventures.com/tours/northern-england-tours-hiking-tours/",
    buttonText: "Walking tours in Northern England",
  },
  "/backpacking/banff-national-park/": {
    link: "https://www.10adventures.com/tours/canadian-rockies-tours-hiking-tours/",
    buttonText: "Find Backpacking tours in the Rockies",
  },
  "/road-biking/victoria/": {
    link: "https://www.10adventures.com/tours/vancouver-island-tours/",
    buttonText: "Check out tours in BC",
  },
  "/hikes/tuscany/": {
    link: "https://www.10adventures.com/tours/tuscany-tours-hiking-tours/",
    buttonText: "Tuscany Hiking Tours",
  },
  "/hikes/jasper/": {
    link: "https://www.10adventures.com/tours/canadian-rockies-tours-hiking-tours/",
    buttonText: "Backpacking tours in the Rockies",
  },
}
